(function(w, d, __tnt) {
    var assetHeader, assetFixedPoint, isAsset, navContainer, navHeight, navScroll, offsetContainer, unfixedPoint;

    var fixedNav = {

        init: function() {
            navContainer = d.getElementById('site-navbar-container');
            isAsset = d.body.classList.contains('blox-asset');

            if (__tnt.client.platform.ios) {
                navContainer.classList.add('affix-sticky');
            } else {
                offsetContainer = (isAsset) ? d.getElementById('main-body-container') : d.getElementById('site-header-container');
                navHeight = navContainer.offsetHeight || navContainer.clientHeight;
                navScroll = false;
                unfixedPoint = 0;
            }

            if (isAsset) {
                assetFixedPoint = w.pageYOffset || d.documentElement.scrollTop;
                assetHeader = d.getElementById('navbar-asset-header');

                if (window.innerWidth > 768) {
                    var navBrand = d.querySelector('.navbar-brand'),
                        navBrandImg = navBrand.querySelector('img');

                    if (navBrandImg && !navBrandImg.complete) {
                        navBrandImg.onload = function() {
                            assetHeader.style.left = (navBrand.clientWidth + 70) + 'px';
                        };
                    } else {
                        assetHeader.style.left = (navBrand.clientWidth + 70) + 'px';
                    }
                }
            }

            if (!__tnt.client.platform.ios || isAsset) {
                w.addEventListener('scroll', fixedNav.navPosition, false);
                w.addEventListener('mousewheel', fixedNav.navPosition, false);
            }
        },

        navPosition: function() {
            if (!navScroll) {
                navScroll = true;
                setTimeout(function() {
                    var currentPoint = w.pageYOffset || d.documentElement.scrollTop;

                    if (!__tnt.client.platform.ios) {
                        var rect = navContainer.getBoundingClientRect(),
                            fixedPoint = rect.top + currentPoint;
                        if (currentPoint >= fixedPoint && currentPoint > unfixedPoint) {
                            if (!navContainer.classList.contains('affix')) {
                                unfixedPoint = fixedPoint;
                                navContainer.classList.add('affix');
                                navContainer.classList.remove('affix-top');
                                try {
                                    offsetContainer.style.marginTop = navHeight + 'px';
                                } catch(e) { /* No offsetContainer in place */ }
                            }
                        } else {
                            if (!navContainer.classList.contains('affix-top')) {
                                navContainer.classList.remove('affix');
                                navContainer.classList.add('affix-top');
                                try {
                                    offsetContainer.style.marginTop = '0px';
                                } catch(e) {}
                            }
                        }
                    }

                    if (isAsset) {
                        if (window.innerWidth > 991 && !__tnt.client.platform.ios) {
                            if (currentPoint > 50 && currentPoint > assetFixedPoint) {
                                if (!assetHeader.classList.contains('sticky')) assetHeader.classList.add('sticky');
                            } else if (currentPoint < assetFixedPoint) {
                                if (assetHeader.classList.contains('sticky')) assetHeader.classList.remove('sticky');
                            }
                            assetFixedPoint = currentPoint;
                        } else if (window.innerWidth < 768) {
                            if (currentPoint > 50) {
                                if (!assetHeader.classList.contains('sticky')) assetHeader.classList.add('sticky');
                            } else {
                                if (assetHeader.classList.contains('sticky')) assetHeader.classList.remove('sticky');
                            }
                        }
                    }

                    navScroll = false;
                }, 25);
            }
        }

    };

    if (d.readyState == 'loading') {
        d.addEventListener('DOMContentLoaded', fixedNav.init, false);
    } else {
        fixedNav.init();
    }
})(window, document, __tnt);